var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Breadscrumbs',{staticClass:"header-bread-scrum",attrs:{"items":_vm.breadcrumbs}}),_c('v-layout',{attrs:{"column":""}},[_c('v-layout',{staticClass:"mb-3",attrs:{"justify-space-between":""}},[_c('v-layout',{staticClass:"col-8 pa-0"},[_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":"","clearable":"","prepend-inner-icon":"mdi-magnify","placeholder":((_vm.$t('place_holders.name')) + "/" + (_vm.$t('customer_phone')))},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onClickSearch.apply(null, arguments)}},model:{value:(_vm.objSearch),callback:function ($$v) {_vm.objSearch=$$v},expression:"objSearch"}}),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.dates,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.dates=$event},"update:return-value":function($event){_vm.dates=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"ml-8 mr-4 col-4",attrs:{"append-icon":"mdi-calendar","outlined":"","dense":"","hide-details":"","readonly":"","clearable":"","value":_vm.dateRangeText,"placeholder":_vm.$t('place_holders.registration_period')},on:{"input":_vm.onInputDateRange}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"range":"","no-title":"","locale":_vm.locale,"min":_vm.minAllowedDate,"max":_vm.maxAllowedDate},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.onSelectedDates}},[_vm._v(" "+_vm._s(_vm.$t('select'))+" ")])],1)],1),(_vm.lstPostOffice.length > 0)?_c('v-autocomplete',{staticClass:"mr-2 col-3",attrs:{"outlined":"","dense":"","clearable":"","hide-details":"","filter":_vm.filterItems,"no-data-text":_vm.$t('no-data'),"placeholder":_vm.$t('place_holders.choice-post-office'),"items":_vm.lstPostOffice,"item-text":"name","item-value":"id"},model:{value:(_vm.selectPostOffice),callback:function ($$v) {_vm.selectPostOffice=$$v},expression:"selectPostOffice"}}):_vm._e()],1),_c('v-btn',{staticClass:"col-1",attrs:{"color":"primary"},on:{"click":_vm.onClickSearch}},[_vm._v(" "+_vm._s(_vm.$t('search'))+" ")])],1),_c('v-data-table',{staticClass:"border-primary",attrs:{"hide-default-footer":"","no-data-text":_vm.$t('not_found'),"headers":_vm.headers,"header-props":{ sortIcon: 'mdi-menu-swap' },"items":_vm.items},on:{"click:row":_vm.viewDetail},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"max-width-7 text-truncate"},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"max-width-7 text-truncate"},[_vm._v(" "+_vm._s(item.email)+" ")])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"footer",fn:function(){return [_c('custom-pagination',{attrs:{"page":_vm.page,"start-record":_vm.computedStartRecord,"end-record":_vm.computedEndRecord,"total-record":_vm.totalRecord,"total-page":_vm.totalPage,"on-change-page":_vm.onChangePage}})]},proxy:true}],null,true)})],1),_c('dialog-loading',{attrs:{"message":_vm.$t('processing')},model:{value:(_vm.showLoading),callback:function ($$v) {_vm.showLoading=$$v},expression:"showLoading"}}),_c('dialog-notification',{attrs:{"icon-src":_vm.getIcon,"message":_vm.message},model:{value:(_vm.showNoti),callback:function ($$v) {_vm.showNoti=$$v},expression:"showNoti"}}),_c('dialog-confirm-token-expried',{model:{value:(_vm.showConfirmTokenExpried),callback:function ($$v) {_vm.showConfirmTokenExpried=$$v},expression:"showConfirmTokenExpried"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }